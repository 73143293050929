import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
//import { ConfigProvider } from 'antd';
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import {LANGUAGE} from "./constant";
import { IntlProvider, addLocaleData } from "react-intl";
import arLocaleData from "react-intl/locale-data/ar";
import { composeWithDevTools } from "redux-devtools-extension";
import allReducers from "./modules";
import {ASSIGN_LANGUAGE} from "./modules/lang";
import {ASSIGN_REQUEST_NUMBER} from "./modules/BindingPharmacy/requestNumber";
import translations from "./i18n/locales";
import retainState from "./RetainState";
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
// import { getQuery } from "./Helper/queryString";

import "./styles/index.less";

Bugsnag.start({
  apiKey: '1185ef4f163d1e6f0a68714fc1a65d65',
  plugins: [new BugsnagPluginReact()]
});
BugsnagPerformance.start({ apiKey: '1185ef4f163d1e6f0a68714fc1a65d65' });

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React);


const Desktop = lazy(() =>
  import("./AppWrapper")
);

const middleware = [thunk, retainState];

let store;
if (process.env.NODE_ENV === "development") {
  store = createStore(
    allReducers,
    composeWithDevTools(applyMiddleware(...middleware))
  );
} else {
  store = createStore(allReducers, applyMiddleware(...middleware));
}


const lang = localStorage.getItem("lang");
if(lang===LANGUAGE.ARAB){
    document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    store.dispatch({ type: ASSIGN_LANGUAGE, payload: {lang:lang}});
}
else if(lang===LANGUAGE.ENGLISH){
    document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    store.dispatch({ type:ASSIGN_LANGUAGE, payload: {lang:lang}});
}
else{
    document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    store.dispatch({ type:ASSIGN_LANGUAGE, payload: {lang:"en"}});
    localStorage.setItem("lang", "en");
}


const requestNumber = localStorage.getItem("requestNumber");
if(requestNumber){
    store.dispatch({ type: ASSIGN_REQUEST_NUMBER, payload: {requestNumber:requestNumber}});
}
// Bugsnag.notify(new Error('Test error'))


addLocaleData(arLocaleData);
/* fetch locale */
// const { search } = window.location;
// const { locale = "en" } = getQuery(search);
const messages =  translations[lang];

ReactDOM.render(
  <ErrorBoundary>
  <Provider store={store}>
    <IntlProvider locale={lang} key={lang} messages={messages}>
	<Suspense fallback={null}>
	
        <Desktop />
	
      </Suspense>
    </IntlProvider>
	</Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

